<template>
  <b-card
    :img-src="require('@/assets/images/series/brief.jpg')"
    img-top
    class="card-profile"
  >
    <!-- 联赛信息 -->
    <div class="profile-image-wrapper">
      <div class="profile-image p-0">
        <b-avatar
          size="114"
          variant="light"
          :src="require('@/assets/images/series/level.jpg')"
        />
      </div>
    </div>
    <h3>{{ SeriesName }}</h3>
    <h6 class="text-muted">
      {{ RaceCategory }}
    </h6>
    <b-badge
      class="profile-badge"
      variant="light-primary"
    >
      B级赛事
    </b-badge>
    <hr class="mb-2">

    <!-- 联赛数据 -->
    <div class="d-flex justify-content-between align-items-center">
      <div>
        <h6 class="text-muted font-weight-bolder">
          分站数
        </h6>
        <h3 class="mb-0">
          {{ StageCount }}
        </h3>
      </div>
      <div>
        <h6 class="text-muted font-weight-bolder">
          车手
        </h6>
        <h3 class="mb-0">
          {{ RaceNum }}
        </h3>
      </div>
      <div>
        <h6 class="text-muted font-weight-bolder">
          人次
        </h6>
        <h3 class="mb-0">
          {{ ManTime }}
        </h3>
      </div>
    </div>

    <!-- 分站人数 -->
    <div>
      <vue-apex-charts
        ref="perStageChart"
        type="line"
        height="160"
        :options="chartOptions"
        :series="series"
      />
      <div class="mb-1 text-center mt-1">
        分站人数
      </div>
    </div>
  </b-card>
</template>

<script>
import Vue from 'vue'
import {
  BCard, BAvatar, BBadge,
} from 'bootstrap-vue'
import axios from '@axios'
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    BCard,
    BBadge,
    BAvatar,
    VueApexCharts,
  },
  props: {
    sid: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      SeriesName: '',
      SeriesId: 0,
      RaceCategory: '',
      ManTime: 0,
      RaceNum: 0,
      StageCount: 0,
      chartOptions: {
        chart: {
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        markers: {
          strokeWidth: 7,
          strokeOpacity: 1,
          // strokeColors: [$themeColors.light],
          // colors: [$themeColors.warning],
        },
        // colors: [$themeColors.warning],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'straight',
        },
        grid: {
          xaxis: {
            lines: {
              show: true,
            },
          },
          yaxis: {
            lines: {
              show: false,
            },
          },
        },
        tooltip: {
          custom(data) {
            return `${'<div class="px-1 py-50"><span>'}${
              data.series[data.seriesIndex][data.dataPointIndex]
            }人</span></div>`
          },
        },
        xaxis: {
        },
        yaxis: {
          min: 0,
          labels: {
            show: false,
          },
        },
      },
      series: [
        {
          data: [],
        },
      ],
    }
  },
  created() {
    this.SeriesId = Number(this.sid)
    axios.get(`${Vue.prototype.$Api_Series_Info}${this.SeriesId}`)
      .then(response => {
        this.SeriesName = response.data[0].SeriesName
        this.RaceCategory = response.data[0].RaceCategory
        this.StageCount = response.data[0].StageCount
      })
    axios.get(`${Vue.prototype.$Api_Series_RaceNum}${this.SeriesId}`)
      .then(response => {
        this.ManTime = response.data[0].ManTime
        this.RaceNum = response.data[0].RaceNum
      })
    axios.get(`${Vue.prototype.$Api_Series_DriversPerStage}${this.SeriesId}`)
      .then(response => {
        response.data.forEach(element => {
          this.series[0].data.push(element.Drivers)
        })
        this.$refs.perStageChart.updateSeries(this.series)
      })
  },
}
</script>
