<template>
  <b-card-code
    title="分站信息"
    no-body
  >
    <b-table
      striped
      :fields="fields"
      :items="items"
      responsive
      class="mb-0"
    >
      <template #cell(TrackNameAbbr)="data">
        <h-link
          :id="data.item.tid"
          :text="data.item.TrackNameAbbr"
          to="track"
          type="track"
        />
      </template>

      <template #cell(RaceStage)="data">
        {{ data.value }}
      </template>

      <template #cell(rid)="data">
        <b-button
          size="sm"
          variant="outline-primary"
        >
          <h-link
            :id="data.item.rid"
            text="查看详情"
            to="race"
            type="race"
          />
        </b-button>
      </template>

      <template #cell(Round)="data">
        {{ data.value }}
      </template>

      <template #cell(FirstLapDriver)="data">
        <h-link
          :id="data.item.FirstLapDid"
          :text="data.item.FirstLapDriver"
          to="driver"
          type="driver"
        />
      </template>

      <template #cell(SecondLapDriver)="data">
        <h-link
          :id="data.item.SecondLapDid"
          :text="data.item.SecondLapDriver"
          to="driver"
          type="driver"
        />
      </template>

      <template #cell(ThirdLapDriver)="data">
        <h-link
          :id="data.item.ThirdLapDid"
          :text="data.item.ThirdLapDriver"
          to="driver"
          type="driver"
        />
      </template>

      <template #cell()="data">
        {{ data.value }}
      </template>
    </b-table>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import Vue from 'vue'
import axios from '@axios'
import {
  BTable, BButton,
} from 'bootstrap-vue'
import HLink from '@/views/common/h-link.vue'

export default {
  components: {
    BCardCode,
    BTable,
    HLink,
    BButton,
  },
  props: {
    sid: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      lastStageNum: 0,
      roundFlag: false,
      fields: [
        { key: 'RaceStage', label: '分站', formatter: this.getStageNum },
        { key: 'rid', label: '赛事详情' },
        { key: 'Date', label: '日期' },
        { key: 'TrackNameAbbr', label: '赛道' },
        { key: 'Round', label: '轮数', formatter: this.getRound },
        { key: 'Class', label: '组别' },
        { key: 'RaceTime', label: '时长' },
        { key: 'RaceLaps', label: '圈数' },
        { key: 'QualifyingPoleTime', label: '杆位', formatter: this.getPoleLapTime },
        { key: 'BestLaptime', label: '正赛最快圈', formatter: this.getLapTime },
        { key: 'FirstLapDriver', label: '冠军' },
        { key: 'SecondLapDriver', label: '亚军' },
        { key: 'ThirdLapDriver', label: '季军' },
      ],
      items: [
      ],
    }
  },
  created() {
    axios.get(`${Vue.prototype.$Api_Series_AllStages}${this.sid}`)
      .then(response => {
        this.items = response.data
      })
  },
  methods: {
    getRound(value) {
      let round = ''
      if (value === 1) {
        round = '第1轮'
      } else if (value === 2) {
        round = '第2轮'
      } else {
        round = '单轮制'
      }
      return round
    },
    getStageNum(value) {
      return value
    },
    getPoleLapTime(value) {
      let laptime = ''
      if (value === 99999999) {
        laptime = ''
      } else {
        laptime = this.$convertToLapTime(value)
      }
      return laptime
    },
    getLapTime(value) {
      return this.$convertToLapTime(value)
    },
  },
}
</script>
