<template>
  <b-card-code no-body>
    <b-card-body>
      <b-table
        stacked
        :items="pdatainfo"
        :fields="fields"
      />
    </b-card-body>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BTable, BCardBody,
} from 'bootstrap-vue'

export default {
  components: {
    BCardCode,
    BTable,
    BCardBody,
  },
  props: {
    sid: {
      type: Number,
      default: 0,
    },
    pdatainfo: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      fields: [
        { key: 'SeriesName', label: '联赛名称' },
        { key: 'Year', label: '比赛年份' },
        {
          key: 'Season',
          label: '比赛季节',
          formatter: this.getSeason,
        },
        { key: 'RaceCategory', label: '赛事系列' },
        {
          key: 'Platform',
          label: '比赛平台',
          formatter: this.getPlatform,
        },
        {
          key: 'Status',
          label: '赛季状态',
          formatter: this.getStatus,
        },
        {
          key: 'hasTeam',
          label: '比赛属性',
          formatter: this.getMode,
        },
      ],
    }
  },
  methods: {
    getPlatform(value) {
      return this.$getPlatformFullName(value)
    },
    getSeason(value) {
      return this.$getSeasonName(value)
    },
    getStatus(value) {
      return this.$getSeasonStatus(value)
    },
    getMode(value) {
      return this.$getRaceMode(value)
    },
  },
}
</script>
