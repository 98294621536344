<template>
  <section>
    <!-- 联赛详情页 第一行 左：卡片 右：信息表+饼图-->
    <b-row class="match-height">
      <!-- 第一列，联赛卡片信息 -->
      <b-col lg="4">
        <SeriesDetailsBrief :sid="currentSeriesId" />
      </b-col>

      <!-- 第二列，信息表+饼图 -->
      <b-col lg="8">
        <SeriesDetailsInfo
          :sid="currentSeriesId"
          :pdatainfo="DataInfo"
        />
        <SeriesDetailsChart
          :sid="currentSeriesId"
          :pfinishrate="chartFinishRateData"
          :ppointrate="chartGetPointsData"
          :ppodiumrate="chartPodiumsData"
        />
      </b-col>
    </b-row>

    <!-- 联赛详情页 第二行 分站详情 -->
    <b-row class="match-height">
      <b-col lg="12">
        <SeriesDetailsStages
          :sid="currentSeriesId"
        />
      </b-col>
    </b-row>

    <!-- 联赛详情页 第三行 积分榜 -->
    <b-row class="match-height">
      <b-col lg="12">
        <SeriesDetailsStandings
          :sid="currentSeriesId"
          :phasgroups="hasGroups"
          :phasteam="hasTeam"
        />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import Vue from 'vue'
import { BRow, BCol } from 'bootstrap-vue'
import axios from '@axios'
import SeriesDetailsBrief from './SeriesDetailsBrief.vue'
import SeriesDetailsInfo from './SeriesDetailsInfo.vue'
import SeriesDetailsChart from './SeriesDetailsChart.vue'
import SeriesDetailsStages from './SeriesDetailsStages.vue'
import SeriesDetailsStandings from './SeriesDetailsStandings.vue'

export default {
  components: {
    BRow,
    BCol,
    SeriesDetailsBrief,
    SeriesDetailsInfo,
    SeriesDetailsChart,
    SeriesDetailsStages,
    SeriesDetailsStandings,
  },
  data() {
    return {
      currentSeriesId: 0,
      DataInfo: [],
      chartFinishRateData: [],
      chartGetPointsData: [],
      chartPodiumsData: [],
      ManTime: 0,
      RaceNum: 0,
      FinishRaceNum: 0,
      PointNum: 0,
      PodiumNum: 0,
      hasGroups: 0,
      hasTeam: 0,
    }
  },
  created() {
    this.currentSeriesId = Number(this.$router.currentRoute.params.sid)
    axios.get(`${Vue.prototype.$Api_Series_Info}${this.currentSeriesId}`)
      .then(response => {
        this.DataInfo = response.data
        this.hasGroups = this.DataInfo[0].HasGroups
        this.hasTeam = this.DataInfo[0].HasTeam
      })
    Promise
      .all([
        axios.get(`${Vue.prototype.$Api_Series_RaceNum}${this.currentSeriesId}`),
        axios.get(`${Vue.prototype.$Api_Series_FinishRaceNum}${this.currentSeriesId}`),
        axios.get(`${Vue.prototype.$Api_Series_PointNum}${this.currentSeriesId}`),
        axios.get(`${Vue.prototype.$Api_Series_PodiumNum}${this.currentSeriesId}`)])
      .then(values => {
        this.ManTime = values[0].data[0].ManTime
        this.RaceNum = values[0].data[0].RaceNum
        this.FinishRaceNum = values[1].data[0].FinishRaceNum
        this.PointNum = values[2].data[0].PointNum
        this.PodiumNum = values[3].data[0].PodiumNum
        this.chartFinishRateData = [this.FinishRaceNum, this.ManTime - this.FinishRaceNum]
        this.chartGetPointsData = [this.PointNum, this.RaceNum - this.PointNum]
        this.chartPodiumsData = [this.PodiumNum, this.RaceNum - this.PodiumNum]
      })
  },
  methods: {
    getFinishRate() {
      return this.FinishRaceNum / this.ManTime
    },
    getPointsRate() {
      return this.PointNum / this.RaceNum
    },
    getPodiumRate() {
      return this.PodiumNum / this.RaceNum
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
