<template>
  <b-card>
    <b-row>
      <b-col cols="4">
        <vue-apex-charts
          v-if="pfinishrate && pfinishrate.length"
          ref="finishRateChart"
          type="donut"
          height="200"
          :options="chartFinishiRateOptions"
          :series="chartFinishRateData"
        />
        <div class="mb-1 text-center mt-1">
          完赛比例
        </div>
      </b-col>
      <b-col cols="4">
        <vue-apex-charts
          v-if="ppointrate && ppointrate.length"
          type="donut"
          height="200"
          :options="chartGetPointsOptions"
          :series="chartGetPointsData"
        />
        <div class="mb-1 text-center mt-1">
          获得积分
        </div>
      </b-col>
      <b-col cols="4">
        <vue-apex-charts
          v-if="ppodiumrate && ppodiumrate.length"
          type="donut"
          height="200"
          :options="chartPodiumsOptions"
          :series="chartPodiumsData"
        />
        <div class="mb-1 text-center mt-1">
          登上领奖台
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    VueApexCharts,
  },
  props: {
    sid: {
      type: Number,
      default: 0,
    },
    pfinishrate: {
      type: Array,
      default: () => [],
    },
    ppointrate: {
      type: Array,
      default: () => [],
    },
    ppodiumrate: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      chartFinishRateData: [],
      chartFinishiRateOptions: {
        animations: {
          enabled: true,
          easing: 'easeinout',
          speed: 800,
          animateGradually: {
            enabled: true,
            delay: 150,
          },
          dynamicAnimation: {
            enabled: true,
            speed: 350,
          },
        },
        legend: {
          show: false,
        },
        labels: ['完赛', '退赛'],
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                value: {
                  formatter(val) {
                    // eslint-disable-next-line radix
                    return `${parseInt(val)}`
                  },
                },
              },
            },
          },
        },
      },
      chartGetPointsData: [],
      chartGetPointsOptions: {
        legend: {
          show: false,
        },
        labels: ['获得积分', '无积分'],
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                value: {
                  formatter(val) {
                    // eslint-disable-next-line radix
                    return `${parseInt(val)}`
                  },
                },
              },
            },
          },
        },
      },
      chartPodiumsData: [],
      chartPodiumsOptions: {
        legend: {
          show: false,
        },
        labels: ['登台', '未登台'],
        colors: [
          '#f95959',
          '#44617b',
        ],
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                value: {
                  formatter(val) {
                    // eslint-disable-next-line radix
                    return `${parseInt(val)}`
                  },
                },
              },
            },
          },
        },
      },
    }
  },
  watch: {
    pfinishrate(val) {
      this.chartFinishRateData = val
    },
    ppointrate(val) {
      this.chartGetPointsData = val
    },
    ppodiumrate(val) {
      this.chartPodiumsData = val
    },
  },
  created() {
  },
}
</script>
