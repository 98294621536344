<template>
  <div>
    <b-card-code
      v-if="phasteam === 1"
      title="车队积分榜"
      no-body
    >
      <b-table
        v-for="items in itemsTeamPoints"
        :key="items.data.key"
        striped
        :fields="fieldsTeamPoints"
        :items="items.data"
        responsive
        class="mb-0"
      />
    </b-card-code>

    <b-card-code
      v-if="phasgroups === 0"
      title="个人积分榜"
      no-body
    >
      <b-table
        striped
        hover
        :fields="fieldsPersonalPoints"
        :items="itemsPersonalPoints"
        responsive
        class="mb-0"
      />
    </b-card-code>

    <b-card-code
      v-for="items in itemsPersonalPointsByClass"
      v-else
      :key="items.key"
      title="车手积分榜（分组别）"
      no-body
    >
      <b-table
        striped
        :fields="fieldsPersonalPoints"
        :items="items.data"
        responsive
        class="mb-0"
      />
    </b-card-code>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import Vue from 'vue'
import axios from '@axios'
import {
  BTable,
} from 'bootstrap-vue'

export default {
  components: {
    BCardCode,
    BTable,
  },
  props: {
    sid: {
      type: Number,
      default: 0,
    },
    phasgroups: {
      type: Number,
      default: 0,
    },
    phasteam: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      lastStageNum: 0,
      roundFlag: false,
      fieldsPersonalPoints: [], // 个人积分表格列头
      itemsPersonalPoints: [], // 个人积分表格原始数据
      totalRounds: 0, // 赛季总轮数
      allClasses: [], // 所有的组别
      allTeams: [], // 所有的参赛队伍
      allDrivers: [], // 每个车手的分站数据
      itemsPersonalPointsByClass: [], // 分组情况下每个车手的分站数据
      fieldsTeamPoints: [
        { key: 'name', label: '车队', thStyle: { width: '40%' } },
        { key: 'veh', label: '车型', thStyle: { width: '30' } },
        { key: 'points', label: '积分', thStyle: { width: '30%' } },
      ],
      itemsTeamPoints: [], // 车队积分原始数据
    }
  },
  created() {
    axios.get(`${Vue.prototype.$Api_Series_Standings}${this.sid}`)
      .then(response => {
        this.items = response.data
        // 处理个人积分
        this.parseRawDriverData(response.data)
      })
    axios.get(`${Vue.prototype.$Api_Series_TeamStandings}${this.sid}`)
      .then(response => {
        if (this.phasteam === 1) {
          this.parseRawTeamData(response.data)
        }
      })
  },
  methods: {
    // 将原始的API数据转化为结构化的数据
    parseRawDriverData(data) {
      this.setSeasonProperties(data)
      this.reOrgData(data)
      this.generateItemsPersonalPoints()
    },
    // 初始化联赛的基本信息
    setSeasonProperties(data) {
      this.totalRounds = data[0].ETS
      this.allClasses = Array.from(new Set(data.map(({ Class }) => Class)))
      this.allTeams = Array.from(new Set(data.map(({ TeamName }) => TeamName)))
    },
    // 为每个车手构造结构化数据 from parseRawData
    reOrgData(data) {
      data.forEach(item => {
        if (!this.allDrivers.some(one => one.name === item.Name)) { // 如果当前数组不包含该车手的名字
          const driver = this.generateDriverObject(item)
          this.allDrivers.push(driver)
        } else {
          const index = this.allDrivers.findIndex(x => x.name === item.Name)
          this.allDrivers[index].pointPerStage[parseInt(item.S, 10)] = item.Point
          if (item.Point > 0) { // 累加个人积分
            this.allDrivers[index].pointPerStage[0] += item.Point
          }
          if (!this.allDrivers[index].class.includes(item.Class)) {
            this.allDrivers[index].class.push(item.Class)
          }
        }
      })
    },
    // 根据数据模型填充车手数据
    generateDriverObject(item) {
      const pointArray = Array(this.totalRounds + 1).fill(-1)
      const driverTemplate = { // 数据模型
        name: '',
        pointPerStage: pointArray,
        class: [],
      }
      driverTemplate.name = item.Name
      driverTemplate.class.push(item.Class)
      driverTemplate.pointPerStage[parseInt(item.S, 10)] = item.Point // 数组从1开始计数，第0个是总分
      if (item.Point > 0) { // 累加个人积分
        driverTemplate.pointPerStage[0] = item.Point
      }
      return driverTemplate
    },
    // 将结构化的车手分站数据转化为分站表格
    generateItemsPersonalPoints() {
      // 根据总积分排序
      const all = this.allDrivers.sort((a, b) => b.pointPerStage[0] - a.pointPerStage[0])

      if (this.phasgroups === 0) { // 不分组别的车手积分数据
        all.forEach(item => {
          const driver = this.getDriverElement(item)
          this.itemsPersonalPoints.push(driver)
        })
      } else { // 分组别的车手积分数据，例如HEC
        // 先根据组别总数建立N个组别积分的车手数据的数组
        for (let i = 0; i < this.allClasses.length; i += 1) {
          this.itemsPersonalPointsByClass.push({
            key: this.allClasses[i],
            data: [],
          })
        }
        all.forEach(item => { // 将车手数据推送到对应的组别的object中去
          const driver = this.getDriverElement(item)
          const index = this.itemsPersonalPointsByClass.findIndex(x => x.key === driver.组别)
          this.itemsPersonalPointsByClass[index].data.push(driver)
        })
      }
    },
    // 根据每一条积分记录分发到车手数据里
    getDriverElement(item) {
      const driver = { // 数据模型
        车手: '',
        组别: '',
        总积分: 0,
      }
      driver.车手 = item.name
      let classNum = 0
      item.class.forEach(x => {
        if (classNum === 0) {
          driver.组别 += x
          classNum += 1
        } else {
          driver.组别 = `${driver.组别} | ${x}`
        }
      })
      if (item.pointPerStage[0] !== -1) {
        driver.总积分 = parseInt(item.pointPerStage[0], 10)
      } else {
        driver.总积分 = 0
      }
      for (let i = 1; i < item.pointPerStage.length; i += 1) {
        if (item.pointPerStage[i] !== -1) {
          driver[`r${i}`] = item.pointPerStage[i]
        } else {
          driver[`r${i}`] = ''
        }
      }
      return driver
    },
    // 处理车队积分
    parseRawTeamData(data) {
      // 初始化每个组别
      this.allClasses.forEach(item => {
        const teamTable = { // 数据模型
          key: item,
          data: [],
        }
        this.itemsTeamPoints.push(teamTable)
      })
      data.forEach(item => {
        const teamInfo = {
          name: item.TeamNameC,
          veh: item.Veh,
          points: item.TeamPoint,
        }
        const index = this.itemsTeamPoints.findIndex(x => x.key === item.Class)
        this.itemsTeamPoints[index].data.push(teamInfo)
      })
      // 排序
      for (let i = 0; i < this.itemsTeamPoints.length; i += 1) {
        this.itemsTeamPoints[i].data.sort((a, b) => b.points - a.points)
      }
    },
  },
}
</script>
